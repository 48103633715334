import { ProjectSubcontractorChangePayload } from 'events.schema';

import { PartialEntity, ProjectSubcontractorEntity } from '@/common/types';

export function convertProjectSubcontractorChangeOperationInput(
  input: ProjectSubcontractorChangePayload,
): Map<string, PartialEntity<ProjectSubcontractorEntity>> {
  return new Map(
    input.subcontractors.map((subcontractor) => [
      subcontractor.subcontractorAssignmentId,
      {
        ...subcontractor,
        id: subcontractor.subcontractorAssignmentId,
        tenant: {
          id: subcontractor.id,
          name: subcontractor.name,
        },
        tenantTradeVariationAssignments: subcontractor.tenantTradeVariationAssignments.map(
          (assignment) => ({
            ...assignment,
            id: '',
            tenantTradeVariation: {
              id: assignment.tenantTradeVariationId,
            },
          }),
        ),
      },
    ]),
  );
}
