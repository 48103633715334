<template>
  <span
    ref="badge"
    class="ds-badge"
    :class="[
      `--${color}`,
      `--${size}`,
      { '--outline': shouldOutline, '--dark': dark, 'tw-mix-blend-multiply': blend },
    ]"
    :style="customStyle"
    :title="badgeTitle"
  >
    <slot name="icon-prepend" v-bind="{ size: 'xs' as DsSvgIconSize }"></slot>
    <slot />
    <slot name="icon-append" v-bind="{ size: 'xs' as DsSvgIconSize }"></slot>
  </span>
</template>

<script setup lang="ts">
import tinycolor from 'tinycolor2';

import { getTextColorForBackground } from '@/helpers/utils/colors';

import { DsBadgeColorType, DsSvgIconSize } from './types';

const props = defineProps({
  color: {
    type: String as PropType<DsBadgeColorType>,
    default: 'grey',
  },
  customColor: { type: String, default: '' },
  // we make this nullable because when using the custom color
  // (e.g. for badges that show a color chosen by the user) we usually want to display a border
  // if the color is very bright
  outline: { type: Boolean as PropType<boolean | null>, default: null },
  size: { type: String as PropType<'sm' | 'md' | 'lg'>, default: 'md' },
  dark: { type: Boolean, default: false },
  blend: { type: Boolean, default: true },
});

const customStyle = computed(() => {
  if (!props.customColor) return undefined;

  return props.outline
    ? {
        color: props.customColor,
        borderColor: props.customColor,
        borderWidth: '1px',
        borderStyle: 'solid',
      }
    : { backgroundColor: props.customColor, color: getTextColorForBackground(props.customColor) };
});

const shouldOutline = computed(() => {
  if (props.outline !== null) {
    return props.outline;
  }
  if (props.customColor) {
    return tinycolor(props.customColor).isLight();
  }

  return false;
});

const badge = ref<HTMLElement | null>(null);
const badgeTitle = computed(() => badge.value?.innerText || '');
</script>

<style scoped>
.ds-badge {
  @apply tw-inline-flex tw-items-center tw-gap-1 tw-truncate;
  @apply tw-rounded-xs;
}

.ds-badge.--white,
.ds-badge.--light {
  @apply tw-bg-white tw-text-grey-700;
}

.ds-badge.--white.--outline,
.ds-badge.--light.--outline {
  @apply tw-border tw-border-solid tw-border-grey-100 tw-bg-white tw-text-grey-500;
}

.ds-badge.--grey {
  @apply tw-bg-grey-50 tw-text-grey-700;
}
.ds-badge.--grey.--dark {
  @apply tw-bg-grey-500 tw-text-white;
}
.ds-badge.--grey.--outline {
  @apply tw-border tw-border-solid tw-border-grey-700 tw-bg-white;
}

.ds-badge.--primary {
  @apply tw-bg-blue-50 tw-text-blue-700;
}
.ds-badge.--primary.--dark {
  @apply tw-bg-blue-700 tw-text-white;
}
.ds-badge.--primary.--outline {
  @apply tw-border tw-border-solid tw-border-blue-700 tw-bg-white;
}

.ds-badge.--attention {
  @apply tw-bg-attention-50 tw-text-attention-700;
}
.ds-badge.--attention.--dark {
  @apply tw-bg-attention-500 tw-text-white;
}
.ds-badge.--attention.--outline {
  @apply tw-border tw-border-solid tw-border-attention-500 tw-bg-white tw-text-attention-500;
}

.ds-badge.--warning {
  @apply tw-bg-warning-50 tw-text-warning-700;
}
.ds-badge.--warning.--dark {
  @apply tw-bg-warning-600 tw-text-white;
}
.ds-badge.--warning.--outline {
  @apply tw-border tw-border-solid tw-border-warning-600 tw-bg-white tw-text-warning-600;
}

.ds-badge.--success {
  @apply tw-bg-success-50 tw-text-success-700;
}
.ds-badge.--success.--dark {
  @apply tw-bg-success-600 tw-text-white;
}
.ds-badge.--success.--outline {
  @apply tw-border tw-border-solid tw-text-success-600 tw-border-success-600 tw-bg-white;
}

.ds-badge.--info {
  @apply tw-bg-info-50 tw-text-info-700;
}
.ds-badge.--info.--dark {
  @apply tw-bg-info-600 tw-text-white;
}
.ds-badge.--info.--outline {
  @apply tw-border tw-border-solid tw-text-info-600 tw-border-info-600 tw-bg-white;
}

.ds-badge.--sm {
  @apply tw-ds-text-xs--medium tw-gap-1 tw-px-2 tw-py-0.5;
}
.ds-badge.--md {
  @apply tw-ds-text-sm--medium tw-gap-1.5 tw-px-2.5 tw-py-0.5;
}
.ds-badge.--lg {
  @apply tw-ds-text-sm--medium tw-gap-1.5 tw-px-3 tw-py-1;
}
</style>
