import {
  InteractiveEntityChanges,
  InteractiveEntityStores,
  MilestoneEntity,
  OrderDependencyEntity,
  OrderEntity,
  PartialEntity,
} from '@/common/types';
import { getDependentEntities } from '@/features/orderDependencies/orderDependencyUtils';

import { RescheduledDependency } from '../../types';

/**
 * We want to show a lag updated notification for all dependencies where the lag has been changed
 * implicitly by the scheduling engine. However, it should only be shown for dependencies where
 * also the successor was changed manually by the user.
 */
export function findRelevantImplicitlyRescheduledDependencies(
  entityStores: InteractiveEntityStores,
  initialChanges: InteractiveEntityChanges,
  changesAfterScheduling: InteractiveEntityChanges,
): RescheduledDependency[] {
  const initialDependencyChangesMap = new Map<string, PartialEntity<OrderDependencyEntity>>(
    (initialChanges.update?.dependencies ?? []).map((dependency) => [dependency.id, dependency]),
  );
  const initialOrderChangesMap = new Map<string, PartialEntity<OrderEntity>>(
    (initialChanges.update?.orders ?? []).map((order) => [order.id, order]),
  );
  const initialMilestoneChangesMap = new Map<string, PartialEntity<MilestoneEntity>>(
    (initialChanges.update?.milestones ?? []).map((milestone) => [milestone.id, milestone]),
  );

  const implicitlyUpdatedDependencies =
    changesAfterScheduling.update?.dependencies?.filter(
      (dependency) =>
        dependency.lagInMinutes !== undefined &&
        initialDependencyChangesMap.get(dependency.id)?.lagInMinutes === undefined,
    ) ?? [];

  const relevantImplicitlyUpdatedDependencies = implicitlyUpdatedDependencies
    .map((dependency) => {
      const { from, fromType, to, toType } = getDependentEntities(entityStores, {
        dependencyOrId: dependency.id,
      });

      return {
        id: dependency.id,
        lag: dependency.lagInMinutes!,
        from,
        fromType: fromType ?? '',
        to,
        toType: toType ?? '',
      };
    })
    .filter((dependency) => {
      if (!dependency.to) return false;
      const maybeOrder = initialOrderChangesMap.get(dependency.to.id);
      if (maybeOrder) {
        return maybeOrder.startAt !== undefined || maybeOrder.finishAt !== undefined;
      }
      const maybeMilestone = initialMilestoneChangesMap.get(dependency.to.id);
      if (maybeMilestone) {
        return maybeMilestone.date !== undefined;
      }
      return false;
    });

  return relevantImplicitlyUpdatedDependencies.map((dependency) => ({
    lagInMinutes: dependency.lag,
    fromName: dependency.from?.name ?? '',
    fromType: dependency.fromType,
    toName: dependency.to?.name ?? '',
    toType: dependency.toType,
  }));
}
