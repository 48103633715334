import { IS_PROD_ENV } from './config';

export function useFeatureToggles() {
  const toggles = {
    ENABLE_PRODUCTION_LOGGING_ON_DEV: false,
    ENABLE_STAGING_LOGGING_ON_DEV: false,
    ENABLE_POWER_PROJECT_EXPORT: true,
    ENABLE_P6_EXPORT: true,
  };
  if (IS_PROD_ENV) {
    return toggles;
  }
  Object.keys(toggles).forEach((toggleKey) => {
    try {
      const localStorageValue = localStorage.getItem(toggleKey);
      if (localStorageValue !== null) {
        toggles[toggleKey] = localStorageValue === 'true';
      }
    } catch {
      // Local Storage is disabled.
    }
  });
  return toggles;
}
