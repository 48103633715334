<template>
  <Teleport to="body">
    <DsHover v-slot="{ props: hoverProps }" @hover-start="onStartHover" @hover-end="onEndHover">
      <DsHorizontalSlideTransition>
        <DsNotification
          v-if="activeNotification"
          v-bind="hoverProps"
          :key="activeNotification.id"
          :type="activeNotification.type"
          class="tw-fixed tw-top-0 tw-right-0 tw-z-[99999]"
          @close-request="notificationStore.closeCurrentNotification()"
        >
          <template #icon>
            <DsSvgIcon v-bind="activeNotification.iconProps" />
          </template>
          <template #text>{{
            $t(activeNotification.titleI18nKey, activeNotification.bodyI18nKeyVariables)
          }}</template>
          <template v-if="activeNotification.bodyI18nKey" #supporting-text>
            <!-- eslint-disable vue/no-v-html -->
            <div
              v-html="
                $t(activeNotification.bodyI18nKey, {
                  ...activeNotification.bodyI18nKeyVariables,
                  ...bodyTranslatedVariablesFromKeys,
                })
              "
            />
          </template>
          <template v-if="hasActions" #actions>
            <DsButton
              v-if="activeNotification?.primaryAction"
              size="sm"
              hierarchy="link"
              @click="activeNotification?.primaryAction?.callback()"
            >
              {{
                $t(
                  activeNotification?.primaryAction?.i18nKey,
                  activeNotification?.primaryAction?.i18nKeyVariables,
                )
              }}
            </DsButton>
            <DsButton
              v-if="activeNotification?.secondaryAction"
              size="sm"
              hierarchy="link-grey"
              @click="activeNotification?.secondaryAction?.callback()"
            >
              {{
                $t(
                  activeNotification?.secondaryAction?.i18nKey,
                  activeNotification?.secondaryAction?.i18nKeyVariables,
                )
              }}
            </DsButton>
          </template>
        </DsNotification>
      </DsHorizontalSlideTransition>
    </DsHover>
  </Teleport>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n';

import { useNotificationStore } from '@/features/notifications';

const notificationStore = useNotificationStore();
const activeNotification = computed(() => notificationStore.activeNotification);

const { t } = useI18n();

const bodyTranslatedVariablesFromKeys = computed(() =>
  Object.fromEntries(
    Object.entries(activeNotification.value?.bodyI18nKeyVariablesKeys ?? {}).map(([key, value]) => [
      key,
      t(value),
    ]),
  ),
);

const hasActions = computed<boolean>(() =>
  Boolean(activeNotification.value?.primaryAction || activeNotification.value?.secondaryAction),
);

const onStartHover = () => {
  notificationStore.pauseTimeout();
};

const onEndHover = () => {
  notificationStore.resumeTimeout();
};
</script>
